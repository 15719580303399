import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { toast } from "react-toastify";
import Button from "../components/Shared/Button";
import { orderProductAPI } from "../api/churches";
import { loadStripe } from "@stripe/stripe-js";

const Product = () => {
  const data = useStaticQuery(graphql`
    query {
      TopImage: file(relativePath: { eq: "Top.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LeftBottom: file(relativePath: { eq: "LeftBottom.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      CenterBottom: file(relativePath: { eq: "CenterBottom.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      RightBottom: file(relativePath: { eq: "RightBottom.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [isLoading, setIsLoading] = useState(false);

  const orderProduct = async () => {
    setIsLoading(true);
    const stripe = await loadStripe("pk_live_AuPkYDkZe6nUIQqEqM4Hctf0");
    const { data } = await orderProductAPI();

    const result = await stripe.redirectToCheckout({
      sessionId: data.session.id,
    });
    if (result) {
      setIsLoading(false);
    }
    if (result.error) {
      toast.error("Oops. Something went wrong. Please try again later");
    }
  };
  return (
    <Layout>
      <SEO title="Product Page" />
      <div className="container">
        <h3 className="product-title">LIGHT SAFE CANDLE</h3>
        <h4 className="product-Subtitle">Electronic Votive Stand</h4>
        <div>
          <div className="ImageDiv">
            <Img
              className="TopImage"
              fluid={data.TopImage.childImageSharp.fluid}
            />
          </div>
          <div className="ImageDiv">
            <Img
              className="SubImage"
              fluid={data.LeftBottom.childImageSharp.fluid}
            />
            <Img
              className="SubImage"
              fluid={data.CenterBottom.childImageSharp.fluid}
            />
            <Img
              className="SubImage"
              fluid={data.RightBottom.childImageSharp.fluid}
            />
          </div>
        </div>
        <div>
          <div className="product-place-order">
            <Button
              className="orderButton"
              onClick={orderProduct}
              isLoading={isLoading}
            >
              Order Now
            </Button>
            {/* <button onClick={() => orderProduct()}>Order Now</button> */}
          </div>

          <p className="product-paragraph">
            Electronic Votive Stand provides 62 candles of high luminescence and
            energy efficiency. Controlled manual and online. Additionally, it
            offers safety iron deposit box for donations.
          </p>
          <p className="product-paragraph-sub">
            The main benefits of electronic votive stand compared to standard
            candles are that they do not emit smoke, avoiding deterioration in
            figures and altar pieces, cannot fall over if pushed, significantly
            reducing possibility of various accidents, do not stain with the
            wax, give off no odors, i I additionally have the latest technology
            in energy efficiency.
          </p>
          <p className="product-paragraph-sub">
            Electronic Votive Stand comes with a microprocesor with wireless
            connection to the Internet that allows you to set the duration time
            of lighting the candles and the price on each candle. The LED's
            electric consumption is 60% lower than that of a conventional light
            bulb.
          </p>
          <p className="product-paragraph-sub">
            It comes with lifetime warranty. As well please remember it takes up
            to 17-21 days to make and ship this item.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Product;
