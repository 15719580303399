import api from "./client";

export const getChurches = () =>
  api.get("/public/churches?status=active,progress");

export const getChurch = (id) => api.get(`/public/churches/${id}`);

export const stripeCheckout = (id, candleIds, donateAmount) =>
  api.post(`/public/churches/${id}/checkout/stripe`, {
    candleIds,
    donateAmount,
  });

export const paypalCheckout = (id, candleIds) =>
  api.post(`/public/churches/${id}/transactions/paypal/create`, { candleIds });

export const saveTransaction = (id, candleIds, orderId) =>
  api.post(`/public/churches/${id}/transactions/paypal/save`, { orderId });

export const getTransaction = (id, sessionId) =>
  api.get(
    `/public/churches/${id}/order/success?checkout_session_id=${sessionId}`
  );

export const orderProductAPI = () =>
  api.get("https://api.lightsafecandle.com/product/checkout");

export const sendDonerName = (data) => api.post("public/churches/add/donators", data)
export const getDonatorList = (id) => api.get(`/public/churches/${id}/get/donators`)


